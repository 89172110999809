import {
    Center,
    Text,
    Box,
    Button,
    Image,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ErrorIcon from '../../assets/img/error.svg';
import { useState } from 'react';

export const PaymentKo = () => {
    const { id: orderId } = useParams();
    const { t } = useTranslation('payment');
    const [isLoading, setIsLoading] = useState(false);
    const retry = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.API_BASE_URL}/orders/${orderId}/retry`)
            if (response.ok) {
                const responseData = await response.json();
                window.location.href = responseData.payment_link;
            } else {
                setIsLoading(false);
            }
        } catch (e) {
            console.error(e)
            setIsLoading(false);
        }
    }
    return (
        <>
            <Box textAlign='center' py={{ base: 10, md: 24 }} px={6}>
                <Center mb={12}>
                    <Image width={150} src={ErrorIcon} />
                </Center>
                <Text fontSize='xl' mt={3} mb={2}>
                    {t('PAYMENT_KO_TITLE')}
                </Text>
                <Center>
                    <Text color={'gray.500'} mb={6} maxW={650}>
                        {t('PAYMENT_KO_DESCRIPTION')}
                    </Text>
                </Center>
                <Button
                    size='lg'
                    onClick={retry}
                    isLoading={isLoading}
                    colorScheme='brand'
                    variant='outline'>
                    {t('RETRY')}
                </Button>
            </Box>
        </>
    )
}