{
    "TERMS_CONDITIONS": "<0>I accept the <1>terms & conditions</1></0>",
    "NAME": "Name",
    "LASTNAME": "Last name",
    "PHONE_PREFIX": "Prefix",
    "SELECT_PHONE_PREFIX": "Select a prefix",
    "PHONE": "Phone",
    "EMAIL": "Email",
    "COUNTRY": "Country",
    "SELECT_A_COUNTRY": "Select a country",
    "CONTINUE": "Continue",
    "ORDER_SUMMARY": "Order summary",
    "TOTAL_DUE_AMOUNT": "Total amount",
    "BUYER_INFORMATION": "Would you like to pay with a card?",
    "ORDER_COMPLETION_INSTRUCTIONS": "Please verify that the order information is correct and choose your prefered payment method.",
    "REQUIRED": "This field is mandatory.",
    "WRONG_EMAIL": "Please enter a valid email address.",
    "ONLY_NUMBERS": "Please enter a valid phone number.",
    "ALREADY_PAID_TITLE": "Order already paid",
    "ALREADY_PAID_DESCRIPTION": "This order has already been paid for.",
    "RETURN_HOME": "Return home",
    "LOADING_ERROR_TITLE": "Loading error",
    "LOADING_ERROR_DESCRIPTION": "The order details could not be obtained. Please try again.",
    "RETRY": "Refresh",
    "ERROR_TITLE_GENERIC": "Someting wen't wrong",
    "ERROR_DESCRIPTION_GENERIC": "The data could not be processed. Please try again.",
    "ERROR_TITLE_MAX_AMOUNT_EXCEEDED": "Maximum amount exceeded",
    "ERROR_DESCRIPTION_MAX_AMOUNT_EXCEEDED": "This operation exceeds the maximum amount configured for this currency. Please download the RevoluPAY app to complete this payment.",
    "ERROR_TITLE_PAYMENT_QUANTITY_EXCEEDED": "Maximum payments exceeded",
    "ERROR_DESCRIPTION_PAYMENT_QUANTITY_EXCEEDED": "You've exceeded the maximum amount of payments. Please download the RevoluPAY app to continue using the service.",
    "PAY_IN_APP": "Pay with the RevoluPay App",
    "PAY_IN_APP_DESCRIPTION": "If you are a registered RevoluPay user you can press the Pay In App button.",
    "PAY_IN_APP_BUTTON": "Pay in App"
}