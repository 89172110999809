import { ChakraProvider } from '@chakra-ui/react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Outlet,
    Route,
    RouterProvider,
    ScrollRestoration
} from 'react-router-dom';
import { theme } from './theme';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { NotFound } from './routes/misc/NotFound';
import { Error } from './routes/misc/Error'
import { CookiesProvider } from 'react-cookie';
import './locale/i18n'
import { Checkout } from './routes/checkout/Checkout';
import { MainLayout } from './layout/MainLayout';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useEffect } from 'react';
import { TermsConditions } from './routes/legal/TermsConditions';
import { PaymentOk } from './routes/payment/PaymentOk';
import { PaymentKo } from './routes/payment/PaymentKo';


const Root = () => (
    <>
        <ScrollRestoration />
        <Outlet />
    </>
)

export const App = () => {
    useEffect(() => {
        const setFp = async () => {
            const fp = await FingerprintJS.load();
            const { visitorId } = await fp.get();
            if (!localStorage.getItem('fp')) {
                localStorage.setItem('fp', visitorId);
            }
        };
        setFp();
    }, []);
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path='/' element={<Root />} errorElement={<Error />}>
                <Route path='not-found' element={<NotFound />} />
                <Route path='orders' element={<MainLayout />}>
                    <Route path=':id/checkout' element={<Checkout />} />
                    <Route path=':id/payment-ok' element={<PaymentOk />} />
                    <Route path=':id/payment-ko' element={<PaymentKo />} />
                </Route>
                <Route index element={<Navigate to='/not-found' replace />} />
                <Route path='*' element={<Navigate to='/not-found' replace />} />
            </Route >
        )
    );
    return (
        <CookiesProvider>
            <HelmetProvider>
                <Helmet>
                    <title>RevoluPOS Card Payment</title>
                    <link rel='canonical' href='https://revolupos-ext.revolupay.es' />
                    <meta name='description' content='RevoluPOS Card Payment' />
                </Helmet>
                <ChakraProvider theme={theme}>
                    <RouterProvider router={router} />
                </ChakraProvider>
            </HelmetProvider>
        </CookiesProvider>
    );
}