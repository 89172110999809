import { Box, Button, Center, Flex, Spinner, Stack, Text, Image, Link, Heading } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OrderSummary } from './OrderSummary';
import { BuyerInformation } from './BuyerInformation';
import { OrderData } from './types/OrderData';
import ErrorIcon from '../../assets/img/error.svg';
import { useTranslation } from 'react-i18next';

export const Checkout = () => {
    const { id: orderId } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [orderData, setOrderData] = useState<OrderData>();
    const [isPaid, setIsPaid] = useState<boolean>(false);
    const [hasLoadingError, setHasLoadingError] = useState<boolean>(false);

    const { t } = useTranslation('checkout');

    const loadData = async () => {
        setHasLoadingError(false);
        setIsLoading(true);
        setIsPaid(false);
        try {
            const response = await fetch(`${process.env.API_BASE_URL}/orders/${orderId}`)
            if (response.ok) {
                setOrderData(await response.json());
            } else {
                if (response.status === 409) {
                    setIsPaid(true);
                } else {
                    setHasLoadingError(true);
                }
            }
        } catch (e) {
            setHasLoadingError(true);
            console.error(e);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData()
    }, []);

    return (
        <>
            {isLoading &&
                <Center height={'full'}>
                    <Spinner size={'lg'} color='black' />
                </Center>
            }
            {isPaid &&
                <Box textAlign='center' py={{ base: 10, md: 24 }} px={6}>
                    <Center>
                        <Image width={150} src={ErrorIcon} />
                    </Center>
                    <Text fontSize='xl' mt={8} mb={2}>
                        {t('ALREADY_PAID_TITLE')}
                    </Text>
                    <Text color={'gray.500'} mb={6}>
                        {t('ALREADY_PAID_DESCRIPTION')}
                    </Text>
                    <Button
                        as={Link}
                        href='https://www.revolupay.es'
                        size='lg'
                        colorScheme='brand'
                        variant='outline'>
                        {t('RETURN_HOME')}
                    </Button>
                </Box>
            }
            {hasLoadingError &&
                <Box textAlign='center' py={{ base: 10, md: 24 }} px={6}>
                    <Center>
                        <Image width={150} src={ErrorIcon} />
                    </Center>
                    <Text fontSize='xl' mt={8} mb={2}>
                        {t('LOADING_ERROR_TITLE')}
                    </Text>
                    <Text color={'gray.500'} mb={6}>
                        {t('LOADING_ERROR_DESCRIPTION')}
                    </Text>
                    <Button
                        onClick={loadData}
                        size='lg'
                        colorScheme='brand'
                        variant='outline'>
                        {t('RETRY')}
                    </Button>
                </Box>
            }
            {(!isLoading && orderData) &&
                <Flex
                    direction={{ base: 'column', md: 'row' }}
                    boxShadow={'sm'}
                    borderRadius='2xl'>
                    <Box
                        flex='1'
                        borderLeftRadius={'2xl'}
                        borderTopRightRadius={{ base: '2xl', md: 'none' }}
                        bg='bg-surface'
                        px={{ base: '6', md: '8', lg: '12', xl: '20' }}
                        py={{ base: '6', md: '8', lg: '12', xl: '20' }}
                    >
                        <Stack spacing={{ base: 12 }}>
                            <OrderSummary order={orderData} />
                            <Stack spacing={4}>
                                <Heading size='xs'>{t('PAY_IN_APP')}</Heading>
                                <Text>
                                    {t('PAY_IN_APP_DESCRIPTION')}
                                </Text>
                                <Center pt={2}>
                                    <Button
                                        variant='outline'
                                        colorScheme='brand'
                                        href={`https://www.revolupay.com/pnp?action=complete_order&order_reference=${orderId}`}
                                        target='_blank'
                                        rel='noopener'
                                        as={Link} >
                                        {t('PAY_IN_APP_BUTTON')}
                                    </Button>
                                </Center>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box
                        flex='1'
                        bg={{ base: 'bg-surface', md: 'bg-canvas' }}
                        borderRightRadius={'2xl'}
                        borderBottomLeftRadius={{ base: '2xl', md: 'none' }}
                        maxW={{ lg: 'md', xl: '40rem' }}
                        px={{ base: '6', md: '8', lg: '12', xl: '20' }}
                        py={{ base: '6', md: '8', lg: '12', xl: '20' }}
                    >
                        <BuyerInformation orderId={orderId ?? ''} />
                    </Box>
                </Flex>
            }
        </>
    )
}