{
    "NOT_FOUND_TITLE": "Página no encontrada",
    "NOT_FOUND_DESCRIPTION": "La página a la que estás intentando acceder no existe",
    "RETURN_HOME": "Volver al inicio",
    "ERROR_TITLE": "Ha ocurrido un error",
    "ERROR_DESCRIPTION": "Ha ocurrido un error completamente inesperado. ¡Lo sentimos mucho!",
    "LEGAL": "Aviso legal",
    "PRIVACY_POLICY":"Política de privacidad",
    "COOKIES_POLICY": "Política de cookies",
    "TERMS_CONDITIONS": "Términos y condiciones",
    "CLOSE": "CERRAR"
}