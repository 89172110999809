import { Center, Container, Flex, Stack,Image } from '@chakra-ui/react'
import { Outlet } from 'react-router'
import { Footer } from './Footer'
import Logo from '../assets/img/revolupay-logo.png'

export const MainLayout = () => (
  <Flex direction='column' flex='1'>
    <Container as='main' role='main' flex='1' px='6' pt='8' pb='16'>
      <Stack spacing={8} height='full'>
        <Center mb={12}>
          <Image sx={{ width: 400 }} src={Logo} />
        </Center>
        <Stack height={'full'} spacing={8}>
          <Outlet />
        </Stack>
      </Stack>
    </Container>
    <Footer />
  </Flex>
)