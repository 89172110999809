import { extendTheme } from '@chakra-ui/react';
import { theme as proTheme } from '@chakra-ui/pro-theme'


export const theme = extendTheme(
    proTheme,
    {
        styles: {
            global: {
                '#root': {
                    height: '100%',
                },
                '.no-scroll-bar': {
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none'
                },
                '.no-scroll-bar::-webkit-scrollbar': {
                    display: 'none'
                },
            },
        },
        config: {
            initialColorMode: 'light',
            useSystemColorMode: false
        },
        colors: {
            ...proTheme.colors,
            brand: {
                50: '#fff1f0',
                100: '#fee7e6',
                200: '#fccfcf',
                300: '#faa8aa',
                400: '#f67476',
                500: '#ee4450',
                600: '#db2436',
                700: '#c21930',
                800: '#9b172d',
                900: '#82172c',
                950: '#4b0712',
            }
        }
    }
);