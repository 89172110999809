import { CreateToastFnReturn } from '@chakra-ui/react'
import { Country, countries } from 'countries-list';

type ToastProps = {
    title: string,
    description?: string;
    status: 'success' | 'error';
}
export const showToast = (toastInstace: CreateToastFnReturn, props: ToastProps) => {
    toastInstace({
        title: props.title,
        position: 'top-right',
        description: props.description ?? '',
        status: props.status,
        duration: 2000,
        isClosable: true
    });
}

export function formatPrice(
    price?: number,
    currency?: string,
) {
    let locale = 'es-ES';
    if (currency === 'USD') {
        locale = 'en-US';
    } else if (currency === 'CAD') {
        locale = 'en-CA';
    } else if (currency === 'GBP') {
        locale = 'en-GB';
    }
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency ?? 'EUR'
    }).format(price ?? 0)
}

export function getCountries() {
    const countriesList: any[] = [];
    for (const key of Object.keys(countries)) {
        const country = countries[key] as Country;
        countriesList.push({
            label: `${country.emoji} ${country.name}`,
            value: key
        });

    }
    return countriesList;
}
export function getPhonePrefixes() {
    const dialCodesList: any[] = [];
    for (const key of Object.keys(countries)) {
        const country = countries[key] as Country;
        for (const phone of country.phone.split(',')) {
            dialCodesList.push({
                label: `${country.emoji} +${phone} - ${country.name}`,
                value: `${phone}`
            })
        }
    }
    return dialCodesList;
}