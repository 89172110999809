import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  Link,
  Stack,
  Text
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { CheckoutFormData } from './types/CheckoutFormData'
import { Trans, useTranslation } from 'react-i18next'
import { Select } from 'chakra-react-select';
import { getCountries, getPhonePrefixes } from '../../helpers';
import { Validators } from '../../validationPatterns';
import { useState } from 'react';
import { nanoid } from 'nanoid';
import { FeedbackModal } from '../../components/FeedbackModal';


enum ErrorType {
  PAYMENT_QUANTITY_EXCEEDED = 'PAYMENT_QUANTITY_EXCEEDED',
  CURRENCY_NOT_SUPPORTED = 'CURRENCY_NOT_SUPPORTED',
  MAX_AMOUNT_EXCEEDED = 'MAX_AMOUNT_EXCEEDED',
  ORDER_ALREADY_PAID = 'ORDER_ALREADY_PAID',
  GENERIC = 'GENERIC'
}

export const BuyerInformation = ({ orderId }: { orderId: string }) => {
  const { t, i18n } = useTranslation('checkout');
  const {
    handleSubmit,
    control,
    register,
    formState
  } = useForm<CheckoutFormData>();
  const [hasAcceptedConditions, setHasAcceptedConditions] = useState(false);
  const [error, setError] = useState<ErrorType>();
  const submit = (data: CheckoutFormData) => new Promise<void>(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${process.env.API_BASE_URL}/orders`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          order_code: orderId,
          name: data.name,
          last_name: data.lastName,
          phone: `${data.prefix.value}${data.phone}`,
          email: data.email,
          country_code: data.country.value,
          unique_browser_id: localStorage.getItem('fp') ?? nanoid(),
          language: i18n.resolvedLanguage
        })
      })
      if (response.ok) {
        const responseData = await response.json();
        window.location.href = `${responseData.payment_link}?lang=${i18n.resolvedLanguage}`;
      } else {
        if (response.status === 409) {
          const responseData = await response.json();
          if (
            responseData.code === ErrorType.MAX_AMOUNT_EXCEEDED ||
            responseData.code === ErrorType.PAYMENT_QUANTITY_EXCEEDED
          ) {
            setError(responseData.code);
          } else {
            setError(ErrorType.GENERIC);
          }
        } else {
          setError(ErrorType.GENERIC);
        }
        resolve();
      }
    } catch (e) {
      console.error(e)
      setError(ErrorType.GENERIC);
      resolve();
    }
  })

  const termsConditionsLink = i18n.resolvedLanguage === 'es' ? '/terms-conditions-es.pdf':'/terms-conditions-en.pdf';

  return (
    <>
      <FeedbackModal
        title={error!==undefined ? t(`ERROR_TITLE_${error}`) : ''}
        body={error!==undefined ? t(`ERROR_DESCRIPTION_${error}`) : ''}
        type={'ERROR'}
        isOpen={error !== undefined}
        onClose={() => {
          setError(undefined);
        }} />

      <Stack
        spacing={{ base: '6', md: '10' }}
        as='form'
        noValidate
        onSubmit={handleSubmit(submit)} >
        <Heading size='xs'>{t('BUYER_INFORMATION')}</Heading>
        <Stack spacing={{ base: '6', md: '8' }} >
          <FormControl id='name' isRequired isInvalid={formState.errors.name !== undefined}>
            <FormLabel>{t('NAME')}</FormLabel>
            <Input
              placeholder={t('NAME')!!}
              {...register(
                'name',
                {
                  required: t('REQUIRED')!!
                }
              )}
            />
            {formState.errors.name !== undefined &&
              <FormErrorMessage>
                {formState.errors.name.message}
              </FormErrorMessage>
            }
          </FormControl>
          <FormControl id='lastName' isRequired isInvalid={formState.errors.lastName !== undefined}>
            <FormLabel>{t('LASTNAME')}</FormLabel>
            <Input
              placeholder={t('LASTNAME')!!}
              {...register(
                'lastName',
                {
                  required: t('REQUIRED')!!
                }
              )}
            />
            {formState.errors.lastName !== undefined &&
              <FormErrorMessage>
                {formState.errors.lastName.message}
              </FormErrorMessage>
            }
          </FormControl>
          <Controller
            control={control}
            name='prefix'
            rules={{
              required: t('REQUIRED')!!
            }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { error }
            }) => (
              <FormControl id='phonePrefix' isRequired isInvalid={!!error}>
                <FormLabel>{t('PHONE_PREFIX')}</FormLabel>
                <Select
                  placeholder={t('SELECT_PHONE_PREFIX')}
                  options={getPhonePrefixes()}
                  name={name}
                  ref={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
                <FormErrorMessage>{error && error.message}</FormErrorMessage>
              </FormControl>
            )}
          />
          <FormControl id='phone' isRequired isInvalid={formState.errors.phone !== undefined}>
            <FormLabel>{t('PHONE')}</FormLabel>
            <Input
              placeholder={t('PHONE')!!}
              {...register(
                'phone',
                {
                  required: t('REQUIRED')!!,
                  pattern: {
                    value: Validators.phone,
                    message: t('ONLY_NUMBERS')
                  }
                }
              )}
            />
            {formState.errors.phone !== undefined &&
              <FormErrorMessage>
                {formState.errors.phone.message}
              </FormErrorMessage>
            }
          </FormControl>
          <FormControl id='email' isRequired isInvalid={formState.errors.email !== undefined}>
            <FormLabel>{t('EMAIL')}</FormLabel>
            <Input
              placeholder={t('EMAIL')!!}
              {...register(
                'email',
                {
                  required: t('REQUIRED')!!,
                  pattern: {
                    value: Validators.email,
                    message: t('WRONG_EMAIL')
                  }
                }
              )}
            />
            {formState.errors.email !== undefined &&
              <FormErrorMessage>
                {formState.errors.email.message}
              </FormErrorMessage>
            }
          </FormControl>
          <Controller
            control={control}
            name='country'
            rules={{
              required: t('REQUIRED')!!
            }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { error }
            }) => (
              <FormControl id='country' isRequired isInvalid={!!error}>
                <FormLabel>{t('COUNTRY')}</FormLabel>
                <Select
                  placeholder={t('SELECT_A_COUNTRY')!!}
                  options={getCountries()}
                  name={name}
                  ref={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
                <FormErrorMessage>{error && error.message}</FormErrorMessage>
              </FormControl>
            )}
          />
          <HStack>
            <Checkbox
              size={'lg'}
              onChange={() => { setHasAcceptedConditions(!hasAcceptedConditions) }}
              isChecked={hasAcceptedConditions} />
            <Trans t={t} i18nKey={'TERMS_CONDITIONS'}>
              <Text>I accept the <Link href={termsConditionsLink} target='_blank'>terms & conditions</Link></Text>
            </Trans>
          </HStack>
        </Stack>
        <Stack spacing='8'>
          <HStack justify={'stretch'} spacing={6}>
            <Button
              width='full'
              colorScheme='brand'
              type='submit'
              isDisabled={!hasAcceptedConditions}
              isLoading={formState.isSubmitting}
              size='lg'
              variant='solid'
              py='7'>
              {t('CONTINUE')}
            </Button>
          </HStack>
        </Stack>
      </Stack>
    </>
  )
}