import { Box, Button, Container, Link, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const FooterLink = ({ to, label }: { to: string, label: string }) => (
    <Button
        sx={{ _hover: {}, _active: {} }}
        fontWeight='medium'
        variant='link'
        color='white'
        target='_blank'
        as={Link}
        href={to}>
        {label}
    </Button>
)

export const Footer = () => {
    const { t,i18n } = useTranslation('layout');
    const termsConditionsLink = i18n.resolvedLanguage === 'es' ? '/terms-conditions-es.pdf':'/terms-conditions-en.pdf';
    return (
        <Box as='footer' role='contentinfo' bg='#303B4A'>
            <Container p={8}>
                <Stack spacing={4} direction={{ base: 'column', md: 'row' }} justify='center'>
                    <FooterLink to='https://www.revolupay.es/legal' label={t('LEGAL')} />
                    <FooterLink to='https://www.revolupay.es/privacy' label={t('PRIVACY_POLICY')} />
                    <FooterLink to='https://www.revolupay.es/cookies' label={t('COOKIES_POLICY')} />
                    <FooterLink to={termsConditionsLink} label={t('TERMS_CONDITIONS')} />
                </Stack>
            </Container>
        </Box>
    )
}