{
    "NOT_FOUND_TITLE": "Page not found",
    "NOT_FOUND_DESCRIPTION": "The page you're trying to acces does not exist.",
    "RETURN_HOME": "Return home",
    "ERROR_TITLE": "There was an error",
    "ERROR_DESCRIPTION": "Something went' wrong and we can't display the requested information at this time. Please try again later.",
    "LEGAL": "About us",
    "PRIVACY_POLICY":"Privacy Policy",
    "COOKIES_POLICY": "Cookies Policy",
    "TERMS_CONDITIONS": "Terms & Conditions",
    "CLOSE": "CLOSE"
}