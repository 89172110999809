import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import layoutEn from './en/layout.json';
import layoutEs from './es/layout.json';
import checkoutEn from './en/checkout.json';
import checkoutEs from './es/checkout.json';
import termsConditionsEn from './en/termsConditions.json';
import termsConditionsEs from './es/termsConditions.json';
import paymentEn from './en/payment.json';
import paymentEs from './es/payment.json';

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        debug: true,
        supportedLngs: ['en', 'es'],
        resources: {
            en: {
                layout: layoutEn,
                checkout: checkoutEn,
                termsConditions:termsConditionsEn,
                payment: paymentEn
            },
            es: {
                layout: layoutEs,
                checkout: checkoutEs,
                termsConditions:termsConditionsEs,
                payment: paymentEs
            }
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });