import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    ModalFooter,
    Button
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const FeedbackModal = (props: {
    title: string,
    body: string,
    type: 'ERROR' | 'SUCCESS',
    isOpen: boolean,
    onClose: () => void
}) => {
    const { title, body, type, isOpen, onClose } = props;
    const { t } = useTranslation('layout');
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <Alert
                        status={type === 'ERROR' ? 'error' : 'success'}
                        variant='subtle'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        textAlign='center'
                        height='240px'
                    >
                        <AlertIcon boxSize='60px' mr={0} />
                        <AlertTitle mt={4} mb={1} fontSize='lg'>
                            {title}
                        </AlertTitle>
                        <AlertDescription maxWidth='sm'>
                            {body}
                        </AlertDescription>
                    </Alert>
                </ModalBody>
                <ModalFooter>
                    <Button variant='outlined' colorScheme='brand' onClick={onClose}>
                        {t('CLOSE')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )

}