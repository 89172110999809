import {
    Center,
    Text,
    Box,
    Button,
    Image,
    Link
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import SuccessIcon from '../../assets/img/success.svg';

export const PaymentOk = () => {
    const { t } = useTranslation('payment');
    return (
        <>
            <Box textAlign='center' py={{ base: 10, md: 24 }} px={6}>
                <Center mb={12}>
                    <Image width={150} src={SuccessIcon} />
                </Center>
                <Text fontSize='xl' mt={3} mb={2}>
                    {t('PAYMENT_OK_TITLE')}
                </Text>
                <Center>
                    <Text color={'gray.500'} mb={6} maxW={650}>
                        {t('PAYMENT_OK_DESCRIPTION')}
                    </Text>
                </Center>
                <Button
                    as={Link}
                    href='https://www.revolupay.es'
                    size='lg'
                    colorScheme='brand'
                    variant='outline'>
                    {t('RETURN_HOME')}
                </Button>
            </Box>
        </>
    )
}