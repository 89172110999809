{
    "TERMS_CONDITIONS": "<0>¿Aceptas los <1>términos y condiciones?</1></0>",
    "NAME": "Nombre",
    "LASTNAME": "Apellidos",
    "PHONE_PREFIX": "Prefijo",
    "SELECT_PHONE_PREFIX": "Selecciona un prefijo",
    "PHONE": "Teléfono",
    "EMAIL": "Correo electrónico",
    "COUNTRY": "País",
    "SELECT_A_COUNTRY": "Selecciona un país",
    "CONTINUE": "Continuar",
    "ORDER_SUMMARY": "Resumen del pedido",
    "TOTAL_DUE_AMOUNT": "Importe a pagar",
    "BUYER_INFORMATION": "¿Quieres pagar con tarjeta?",
    "ORDER_COMPLETION_INSTRUCTIONS": "Revisa que los datos del pedido sean correctos y selecciona tu forma de pago preferida.",
    "REQUIRED": "Campo obligatorio.",
    "WRONG_EMAIL": "Introduce un correo electrónico válido.",
    "ONLY_NUMBERS": "Solo se aceptan números.",
    "ALREADY_PAID_TITLE": "Pedido ya pagado",
    "ALREADY_PAID_DESCRIPTION": "Este pedido ya ha sido pagado.",
    "RETURN_HOME": "Volver al inicio",
    "LOADING_ERROR_TITLE": "Error de carga",
    "LOADING_ERROR_DESCRIPTION": "No se ha podido obtener la información del pedido. Vuelve a intentarlo.",
    "RETRY": "Recargar",
    "ERROR_TITLE_GENERIC": "Ha ocurrido un error",
    "ERROR_DESCRIPTION_GENERIC": "No se ha podido enviar la información. Vuelve a intentarlo más tarde.",
    "ERROR_TITLE_MAX_AMOUNT_EXCEEDED": "Importe máximo superado",
    "ERROR_DESCRIPTION_MAX_AMOUNT_EXCEEDED": "Esta operación supera el límite configurado para esta moneda.",
    "ERROR_TITLE_PAYMENT_QUANTITY_EXCEEDED": "Límite de pagos superado",
    "ERROR_DESCRIPTION_PAYMENT_QUANTITY_EXCEEDED": "Ya has realizado demasiados pagos. Por favor regístrate en RevoluPAY para continuar pagando más pedidos.",
    "PAY_IN_APP": "Pagar con la APP RevoluPAY",
    "PAY_IN_APP_DESCRIPTION": "Si eres un usuario registrado de RevoluPay puedes pulsar el botón de Pagar en App.",
    "PAY_IN_APP_BUTTON": "Pagar en APP"
}