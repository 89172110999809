import { Image, Box, Heading, Text, Button, Center,Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/img/revolupay-logo.png'

export const NotFound = () => {
    const { t } = useTranslation('layout');
    return (
        <Box textAlign='center' py={{ base: 10, md: 24 }} px={6}>
            <Center mb={12}>
                <Image sx={{ width: 500 }} src={Logo} />
            </Center>
            <Heading
                display='inline-block'
                color={'violet.900'}
                size='2xl'
            >
                404
            </Heading>
            <Heading as='h2' size='2xl' >
                🙉
            </Heading>
            <Text fontSize='xl' mt={3} mb={2}>
                {t('NOT_FOUND_TITLE')}
            </Text>
            <Text color={'gray.500'} mb={6}>
                {t('NOT_FOUND_DESCRIPTION')}
            </Text>
            <Button
                as={Link}
                href='https://www.revolupay.es'
                size='lg'
                colorScheme='brand'
                variant='outline'>
                {t('RETURN_HOME')}
            </Button>
        </Box>
    );
}