import {
  Button,
  Divider,
  Heading,
  HStack,
  Stack,
  Text,
  Image
} from '@chakra-ui/react'
import { OrderData } from './types/OrderData'
import { formatPrice } from '../../helpers';
import { useTranslation } from 'react-i18next';

type OrderSummaryProps = {
  order: OrderData;
}
export const OrderSummary = ({ order }: OrderSummaryProps) => {
  const {t} = useTranslation('checkout');
  return (
    <Stack spacing={{ base: '6', md: '10' }}>
      <Heading size='xs'>{t('ORDER_SUMMARY')}</Heading>
      <Text>{t('ORDER_COMPLETION_INSTRUCTIONS')}</Text>
      <Stack spacing='8'>
        <Stack spacing='6'>
          <HStack spacing={6} justify={'space-between'} alignItems='center'>
            <Stack direction='row' spacing='5' alignItems={'flex-start'}>
              <Image
                width='80px'
                objectFit={'cover'}
                src={order.merchant.logo}
                borderRadius='xl'
              />
              <Stack spacing='3'>
                <Stack spacing='1'>
                  <Text fontWeight='semibold'>{order.merchant.name}</Text>
                  <Text fontSize='sm' align={{ base: 'justify', md: 'left', xl: 'justify' }} fontWeight='thin'> {order.description ?? ''}</Text>
                </Stack>
              </Stack>
            </Stack>
          </HStack>
          <Divider />
        </Stack>
        <Stack spacing='6'>
          <Stack direction='row' justify='space-between'>
            <Text
              fontSize='lg'
              fontWeight='semibold'
              color='gray.700'
            >
              {t('TOTAL_DUE_AMOUNT')}
            </Text>
            <Text fontSize='xl' fontWeight='semibold' color='black' >
              {formatPrice(order.amount, order.currency_code)}
            </Text>
          </Stack>
        </Stack>
      </Stack>

    </Stack>
  )
}